import React from "react"
import Banner from "./Banner"
import List from "./Lists"
import Newsletter from "./Newsletter"

const BlogListing = () => (
  <>
    <Banner />
    <List />
    <Newsletter />
  </>
)

export default BlogListing
